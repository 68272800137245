import axios from "axios";
import axiosRetry from 'axios-retry';

//apply base url for axios
export const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// axiosApi.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("idToken");

axiosRetry(axiosApi, { 
  retries: 1, 
  retryCondition: (error) => {
    if (!error.response) {
      return;
    }
    switch (error.response.status) {
      case 401: case 500: case 501:
        return false; // TURNED OFF
      default:
        return false;
      }
    },
  });

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function download(url, config = {}) {
  return await axiosApi
    .get(url, { responseType: 'blob', ...config })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', response.headers["file-name"]); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
  });
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
