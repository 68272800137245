
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import SetUp from "./SetUp";
import Breadcrumbs, { DASHBOARD_ITEM } from "components/Common/Breadcrumb";

const BrokersPage = () => {

  document.title = "Brokers | Vertex Robot Trading";

  return (
    <>
        <div className="page-content">
            <Breadcrumbs title="Brokers" items={[DASHBOARD_ITEM]} />
            
        </div>
    </>
  );
};


BrokersPage.propTypes = {
    navigate: PropTypes.object,
};
  
export default withRouter(BrokersPage);