import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

export const DASHBOARD_ITEM = {title: "Dashboard", url: "/"};

const Breadcrumb = (props: any) => {

  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">{props.title}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {/* { !props.title && !props.default &&
                <BreadcrumbItem>
                  <Link to="/">Dashboard</Link>
                </BreadcrumbItem>} */}
              { props.items?.map((item: any, index: number) => (
                <BreadcrumbItem key={index} active={props.items.length-1 == index}>
                  {item.url ? <Link to={item.url}>{item.title}</Link> : item.title}
                </BreadcrumbItem>
              ))}
              
              {/* {props.title && <BreadcrumbItem>
                <Link to="/">{props.title}</Link>
              </BreadcrumbItem>} */}
              {props.title && props.items?.length > 0 && <BreadcrumbItem active>{props.title}
                {/* <Link to="#">{props.breadcrumbItem}</Link> */}
              </BreadcrumbItem>}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array
}

export default Breadcrumb
