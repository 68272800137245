import CurrencyFormat from 'components/Common/CurrencyFormat'
import PropTypes from 'prop-types'
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"


const CardPricing = props => {

  const { pricing } = props;

  return (
    <React.Fragment>
      <Col xl="3" md="6">
        <Card className="plan-box">
          <CardBody className="p-4">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h4>{pricing.title}</h4>
                <p className="text-muted fs-5">{pricing.description}</p>
              </div>
              <div className="flex-shrink-0 ms-3">
                { 
                  pricing.icon &&
                    <i className={"bx " + pricing.icon + " h1 text-primary"} style={{lineHeight:1}}/>
                }
                {
                  pricing.images && pricing.images.map((image, index) => 
                    <img key={`${pricing.productId}-img${index}`} src={image} height="80" className="logo-dark-element"/>
                  )
                }
              </div>
            </div>
            <div className="py-4">
              <h2>
                <CurrencyFormat value={(pricing.price)} currency={pricing.currency}/>
                {" "}/{" "}
                <span className="font-size-13">{pricing.duration}</span>
              </h2>
            </div>
            <div className="text-center plan-btn">
              <Link
                to={pricing.link ? pricing.link : `/payment/${pricing.productId}/${pricing.priceId}/${pricing.type}`}
                className="btn btn-primary btn-sm waves-effect waves-light "
                // color="primary" className="btn btn-primary btn-sm waves-effect waves-light" 
              >
                {pricing.button || "Buy Now"}
              </Link>
            </div>

            <div className="plan-features mt-5 fs-5">
              {pricing.features.map((feature, index) => (
                <div className="d-flex py-1" key={`${pricing.id}_feature_${index}`}>
                  <i className="bx bx-checkbox-square text-primary mt-1 me-2"/>
                  <span dangerouslySetInnerHTML={{__html: feature.title}}/>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardPricing.propTypes = {
  pricing: PropTypes.object
}

export default CardPricing
