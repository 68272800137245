
const ChatOnlineStatus = ({member, isBottomRight}) => {

    const getStatus = (member) => {
        if (!member) {
            return "";
        }
        return member.status == "ON-LINE" ? "Online" : "Offline";
    }
    const getStatusClass = (member) => {
        return member?.status == "ON-LINE" ? "bg-success" : "bg-offline";
    }

    return (
        <span style={isBottomRight ? {height: 18, width: 18, right: 0, bottom: -18} : {height: 18, width: 18, left: 0}} 
            className={`position-absolute border border-2 border-white rounded-circle ${getStatusClass(member)}`}><span className="visually-hidden">{getStatus(member)}</span></span>
                  

    );
}

export default ChatOnlineStatus;