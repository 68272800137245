import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import Bots from "./Bots";
import Breadcrumbs, { DASHBOARD_ITEM } from "components/Common/Breadcrumb";

const BotsPage = () => {

    document.title = `My Robots | Vertex Robot Trading${process.env.REACT_APP_ENV != 'PROD' ? ' (' + process.env.REACT_APP_ENV + ')' : ''}`;

    return (
        <>
            <div className="page-content">
                <Breadcrumbs title="My Robots" items={[DASHBOARD_ITEM]} />
                <Bots />
            </div>
        </>
    );

};

BotsPage.propTypes = {
    bots: PropTypes.array,
    onListBots: PropTypes.func,
};

export default withRouter(BotsPage);