/* CHATS */
export const GET_CHATS = "GET_CHATS"
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS"
export const GET_CHATS_FAIL = "GET_CHATS_FAIL"
export const SELECT_CHANNEL = "SELECT_CHANNEL"
export const SELECT_CHANNEL_SUCCESS = "SELECT_CHANNEL_SUCCESS"

/* CONTACTS */
export const GET_CONTACTS = "GET_CONTACTS"
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS"
export const GET_CONTACTS_FAIL = "GET_CONTACTS_FAIL"
export const RECIEVED_USER_STATUS = "RECIEVED_USER_STATUS"
export const RECIEVED_USER_STATUS_SUCCESS = "RECIEVED_USER_STATUS_SUCCESS"

/* MESSAGES */
export const GET_MESSAGES = "GET_MESSAGES"
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS"
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL"
export const ADD_MESSAGE = "ADD_MESSAGE"
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS"
export const ADD_MESSAGE_FAIL = "ADD_MESSAGE_FAIL"
export const RECIEVE_MESSAGE = "RECIEVE_MESSAGE"
export const RECIEVE_MESSAGE_SUCCESS = "RECIEVE_MESSAGE_SUCCESS"
export const DELETE_MESSAGE = "DELETE_MESSAGE"
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS"
export const DELETE_MESSAGE_FAIL = "DELETE_MESSAGE_FAIL"

export const RESET_NEW_COUNT = "RESET_NEW_COUNT"
export const RESET_NEW_COUNT_SUCCESS = "RESET_NEW_COUNT_SUCCESS"
export const RESET_NEW_COUNT_FAIL = "RESET_NEW_COUNT_FAIL"


export const IS_CHAT_OPEN = "IS_CHAT_OPEN"
