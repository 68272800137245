import React from "react";
import { Input, Label } from "reactstrap";



const FormRadio = ({name, options, handleChange, data}) => {

  return (

    <React.Fragment>
      {options.map(option =>
        <div key={option.name} className="fs-4 form-check mb-3">
          <Input
            className="form-check-input"
            type="radio"
            name={name}
            id={name + option.name}
            value={option.name}
            defaultChecked={option.name == data[name]}
            onChange={handleChange}
          />
          <Label
            className="form-check-label"
            htmlFor={name + option.name}
          >
            {option.label}
          </Label>
        </div>
      )}


    </React.Fragment>

  );
}


export default FormRadio;