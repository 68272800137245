import { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import CurrencyFormat from "components/Common/CurrencyFormat";
import { FormattedNumber } from 'react-intl'
import SetUpHelp from "./SetUpHelp";

const StepAccountSize = ({ data, onSelection, colWidths, nextStep, backStep, help }) => {

    const sizes = {
        0: "$500",
        1: "",
        2: "",
        3: "$5,000",
        4: "",
        5: "",
        6: "$50,000",
        7: "",
        8: "",
        9: "$500,000+",
    }

    const sizesAll = {
        0: "500",
        1: "1000",
        2: "2000",
        3: "5000",
        4: "10000",
        5: "20000",
        6: "50000",
        7: "100000",
        8: "200000",
        9: "500000",
    }

    const percts = {
        0: "5%",
        1: "",
        2: "",
        3: "25%",
        4: "",
        5: "",
        6: "2x",
        7: "",
        8: "",
        9: "5x",
    }

    const perctAll = {
        0: "5",
        1: "10",
        2: "20",
        3: "25",
        4: "50",
        5: "100",
        6: "200",
        7: "300",
        8: "400",
        9: "500",
    }

    let iSize = "3";
    if (data.investmentSize) {
        for (const key in sizesAll) {
            if (data.investmentSize == sizesAll[key]) {
                iSize = key;
            }
        }
    } /*else {
        data.investmentSize = sizesAll[iSize];
    }*/
    let pSize = "5";
    if (data.profitSize) {
        for (const key in perctAll) {
            if (data.profitSize == perctAll[key]) {
                pSize = key;
            }
        }
    } /*else {
        data.profitSize = perctAll[pSize];
    }*/

    const [investmentSize, setInvestmentSize] = useState(Number(iSize))
    const [profitSize, setProfitSize] = useState(Number(pSize))


    const resultData = () => {
        return {investmentSize: sizesAll[investmentSize], profitSize: perctAll[profitSize]};
    }

    return (
        <Row>
            <Col {...colWidths[0]}>

                <Card>
                    <CardBody>
                        <p className="fs-4">
                            Your account size?
                        </p>
                        <div className="text-end pt-1 pe-5 fs-2">
                            <CurrencyFormat value={sizesAll[investmentSize]} currency="USD" noTrailingZeros={true}/>
                        </div>
                        <div className="p-5 pb-4 pt-1">
                            <Slider
                                value={investmentSize}
                                min={0}
                                max={9}
                                labels={sizes}
                                tooltip={false}
                                orientation="horizontal"
                                onChange={value => setInvestmentSize(value)}
                            />
                        </div>
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <p className="fs-4">
                            Your anual profit goal?
                        </p>
                        <div className="text-end pt-1 pe-5 fs-2">
                            <span className="me-5">
                                <FormattedNumber style="percent" value={perctAll[profitSize] / 100}/>
                            </span>
                            <span>
                                <CurrencyFormat value={(1 + (perctAll[profitSize] / 100)) * sizesAll[investmentSize]} currency="USD" noTrailingZeros={true}/>
                            </span>
                        </div>
                        <div className="p-5 pb-4 pt-1">
                            <Slider
                                value={profitSize}
                                min={0}
                                max={9}
                                labels={percts}
                                tooltip={false}
                                orientation="horizontal"
                                onChange={value => setProfitSize(value)}
                            />
                        </div>
                    </CardBody>
                </Card>

                <div className="nav-buttons">
                    <Button color="primary" outline onClick={() => onSelection(backStep, resultData())}>Back</Button>
                    <Button color="primary" className="btn btn-primary" onClick={() => onSelection(nextStep, resultData())}>
                        Next
                    </Button>
                </div>

            </Col>
            <Col {...colWidths[1]}>
                <SetUpHelp help={help}/>
            </Col>
        </Row>

    );
}

export default StepAccountSize;