import React, { useEffect, useState } from "react"
import Avatar from 'react-avatar';
import { getAuth } from "firebase/auth";
import { getFirebaseApp } from "helpers/firebase_helper";

const Avatars = ({size, name, photoUrl}: any) => {

    return (
        <React.Fragment>
            <Avatar name={name} src={photoUrl} size={size || "50"} round={true} style={{maxWidth: "none"}} className="img-thumbnail p-0"/>
        </React.Fragment>
    )
}

export default Avatars;