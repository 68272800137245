import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
    Col, Container, Row, Label, Button, Card, CardBody,
    CardHeader,
    Alert
} from "reactstrap";
import Breadcrumbs, { DASHBOARD_ITEM } from "components/Common/Breadcrumb";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { downLoadBot, getBroker } from "helpers/bot_trader_api";
import { getBot } from "store/bot-trader/actions";

const DownloadBotPage = (props) => {

    const dispatch = useDispatch();

    const { botId } = useParams();

    document.title = "Download & Setup | Vertex Robot Trading";

    const [broker, setBroker] = useState({});

    const selectBotState = (state) => (state.bots);
    const BotProperties = createSelector(
      selectBotState,
      (bots) => ({
        bot: bots.bot,
        loading: bots.loading
      })
    );
    const {
        bot
    } = useSelector(BotProperties);

    const onDownload = () => {
        downLoadBot(bot.eaId);
    }

    useEffect(() => {
        dispatch(getBot(botId));
    }, [dispatch]);
    
    
    useEffect(() => {
        getBroker(bot.broker).then(b => setBroker(b));
    }, [bot.broker]);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Download & Setup" items={[DASHBOARD_ITEM, {title: "My Robots", url: "/bots"}]} />
                    <Row className="justify-content-center">
                        <Col md="11" xl="8">
                                                
                            {bot?.isDownload && <Card outline color="info" className="border">
                                <CardHeader className="bg-transparent d-flex">
                          
                                <h3 className="my-0 text-info flex-grow-1">
                                    <i className="mdi mdi-download me-3" />Download & Setup
                                </h3>
                                <Button onClick={() => onDownload()} color="primary"
                                    className="mt-0 text-end">
                                    <i className="mdi mdi-download me-3"/>Download Expert Advisor
                                </Button>
                                </CardHeader>
                                <CardBody>
                                {/* <CardTitle className="mt-0">card title</CardTitle> */}
                                    <div className="fs-5">
                                        <ul>
                                            <li>
                                                Make sure you have completed your <Link to="/setup">Broker Setup Tasks</Link> 
                                            </li>
                                            <li>
                                                Download and install MetaTrader 4 (MT4) from <strong>{broker.name}</strong>
                                            </li>
                                            <li>
                                                In MT4 add your Account and login 
                                                <Alert color="info" className="mt-1 mb-4" role="alert">
                                                    In MT4 in Navigator Window right click on <strong>Accounts</strong> select <strong>Login to Trade Account</strong>. 
                                                    Login in with your Trade Account login details from {broker.name}. 
                                                </Alert>
                                            </li>
                                            <li>
                                                Download your Expert Advisor file <Link to="#" onClick={() => onDownload()}>{bot.eaDownloadFileName}</Link>
                                            
                                                <Alert color="info" className="mt-1 mb-4" role="alert">
                                                    You only need to download this Expert Advisor once! 
                                                    The robot is configured automatically when added to a price chart.
                                                </Alert>
                                            </li>
                                            <li>
                                                Copy downloaded Expert Advisor file to your <strong>MQL4/Experts</strong> folder in your terminal data folder.
                                                <Alert color="info" className="mt-1 mb-4" role="alert">
                                                    In MT4 go to <strong>File &gt; Open Data Folder</strong> to open terminal data folder!
                                                </Alert>
                                            </li>
                                            <li>
                                                Refresh your Export Advisors
                                                <Alert color="info" className="mt-1 mb-4" role="alert">
                                                    In MT4 right click on the Expert Advisors (in the Navigator window) and click <strong>Refresh</strong> 
                                                </Alert>
                                            </li>
                                            <li>
                                                Allow automated trading and add <strong>https://app.vertexrobots.com</strong> app to allowed URL list
                                                <Alert color="info" className="mt-1 mb-4" role="alert">
                                                    In MT4 go to <strong>Tools &gt; Options &gt; Expert Advisors </strong>
                                                    <ul>
                                                        <li>
                                                            <label style={{minWidth: "60px"}}>Check</label> <i className="mdi mdi-check fs-3" /> <strong>Allow automated trading</strong>
                                                        </li>
                                                        <li>
                                                            <label style={{minWidth: "60px"}}>Check</label> <i className="mdi mdi-check fs-3" /> <strong>Allow DLL imports</strong>
                                                        </li>
                                                        <li>
                                                            <label style={{minWidth: "60px"}}>Check</label> <i className="mdi mdi-check fs-3" /> <strong>Allow WebRequest for listed URL</strong>
                                                        </li>
                                                        <li>
                                                            <label style={{minWidth: "60px"}}>Add</label> <i className="mdi mdi-plus fs-3" /> <strong>https://app.vertexrobots.com</strong> to list
                                                        </li>
                                                    </ul>
                                                </Alert>
                                            </li>
                                            <li>Open <strong>{bot.symbol}</strong> price chat</li>

                                            <li>Select <strong>{bot.period}</strong> timeframe</li>
                                            <li>
                                                Add Expert Advisor to chart
                                                <Alert color="info" className="mt-1 mb-4" role="alert">
                                                    Double click Expert Advisor <strong>{bot.eaDownloadFileName.split(".")[0]}</strong> or Drag & Drop on to price chart window
                                                </Alert>
                                            </li>
                                            <li>
                                                Your robot is now running <i style={{position: "relative", top: 4}} className="bx bx-smile fs-2"></i>
                                            </li>
                                        </ul>
                                    </div>
                                   
                                </CardBody>

                            </Card>}
                            <div className="nav-buttons">
                                <Link to="/bots" className="btn btn-outline-primary">
                                    Done
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(DownloadBotPage);