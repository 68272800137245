import { all, fork } from "redux-saga/effects";

//public
import BotsSaga from "./bot-trader/saga";
import ChatsSaga from "./chat/saga";
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(BotsSaga),
    fork(ChatsSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
  ]);
}
