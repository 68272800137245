import xChiefImg from '../../assets/images/brokers/xchief-logo-black.png'
import fbsImg from '../../assets/images/brokers/fbs-logo.png'
import icMarketsImg from '../../assets/images/brokers/ic-markets-logo-black.png'
import roboForexImg from '../../assets/images/brokers/robo-forex-logo.svg'
import blackBullImg from '../../assets/images/brokers/black-bull-logo-black.svg'
import vantageImg from '../../assets/images/brokers/vantage-logo.png'
import vertexRobotsImg from '../../assets/images/banner-logo-dark.png'

export const BROKER_IMAGES = {
    "robo-forex": roboForexImg,
    "x-chief": xChiefImg,
    "fbs": fbsImg,
    "vantage": vantageImg,
    "black-bull": blackBullImg,
    "ic-markets": icMarketsImg, 
    "new-robot": vertexRobotsImg,
    "run-robot": vertexRobotsImg,
}