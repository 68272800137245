import { listBrokers } from "helpers/bot_trader_api";
import { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Label, Input, Button, Form, FormFeedback, InputGroup } from "reactstrap";

import { useFormik } from "formik";
import Select from "react-select";
import { Step } from "./SetUp";
import SetUpHelp from "./SetUpHelp";

const StepExistingBroker = ({ data, onSelection, colWidths, nextStep, backStep, help }) => {


    const [loading, setLoading] = useState(true);
    const [brokerOptions, setBrokerOptions] = useState([]);
    const [selectedBroker, setSelectedBroker] = useState({label: "", value: ""});

    useEffect(() => {
        listBrokers().then((brokers) => {
            const options = brokers.map(b => ({ label: b.name, value: b.id }));
            setBrokerOptions(options);
            setSelectedBroker(options.find(({value}) => value === data.existingBroker?.id));
            setLoading(false);
        });
    }, []);

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            broker: selectedBroker,
            brokerOther: data.existingBroker?.other,
        },
        validate: (values /* only available when using withFormik */) => {
            const errors: any = {};
            if (!values.broker && !values.brokerOther) {
                errors.brokerOther = "Please select your broker or input other broker!"
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = values.broker && values.broker.value 
                            ? {brokerId: values.broker.value, brokerName: values.broker.label} 
                            : {brokerOther: values.brokerOther};
            onSelection(nextStep, data);
        }
    });

    return (
        <Row>
            <Col {...colWidths[0]}>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <Card>
                        <CardBody>
                            <p className="fs-4">
                                Please select your broker?
                            </p>

                            <Row className="p-5 pb-3">
                                <Label htmlFor="formrow-broker-Input" className="col-md-4 col-form-label fs-3">Broker</Label>
                                <div className="col-md-8 fs-4">
                                    <Select className="select2-selection"
                                        id="form-row-broker-Input"
                                        name="broker"
                                        onChange={option => {
                                            let event = { target: { name: 'broker', value: option } }
                                            validation.handleChange(event)
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.broker || ""}
                                        isLoading={loading}
                                        isSearchable={true}
                                        isClearable
                                        options={brokerOptions}
                                    />
                                </div>
                            </Row>

                            <Row className="fs-3 p-5 pt-1">
                                <Label htmlFor="form-row-login-Input" className="col-md-4 col-form-label">Other Broker</Label>
                                <div className="col-md-8 fs-3">
                                    <InputGroup size="lg">
                                        <Input 
                                            name="brokerOther"
                                            type="text"
                                            className="form-control"
                                            id="form-row-login-Input"
                                            placeholder="Your current broker"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.brokerOther || ""}
                                            invalid={validation.touched.brokerOther && validation.errors.brokerOther ? true : false}
                                        />
                                        {(validation.errors.brokerOther)
                                                        && <FormFeedback type="invalid">{validation.errors.brokerOther}</FormFeedback>}
                                    </InputGroup>
                                    
                                </div>
                            </Row>
                        </CardBody>
                    </Card>

                    <div className="nav-buttons">
                        <Button color="primary" outline onClick={()=>onSelection(backStep, {brokerId: null, brokerOther: null, brokerName: null})}>Back</Button>
                        <Button color="primary" className="btn btn-primary" type="submit">
                            Next
                        </Button>
                    </div>

                </Form>
            </Col>
            <Col {...colWidths[1]}>
                <SetUpHelp help={help}/>
            </Col>
        </Row>

    );
}

export default StepExistingBroker;