import { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Button, Form } from "reactstrap";

import { Step } from "./SetUp";
import FormRadio from "./FormRadio";
import { useFormik } from "formik";
import SetUpHelp from "./SetUpHelp";


const StepExperience = ({ data, onSelection, colWidths, nextStep, backStep, help }) => {

    const questions = [
        {
            name: "experienceTrading", label: "Your trading experience level?", options: [
                { name: "0", label: "I'm new to trading" },
                { name: "1", label: "I have some knowledge of trading" },
                { name: "2", label: "I regulary trade" },
            ]
        },
        {
            name: "experienceAutomated", label: "Do you have experience with automated trading robots?", options: [
                { name: "0", label: "I'm new to automated trading" },
                { name: "1", label: "I have some knowledge of automated trading" },
                { name: "2", label: "I regularly trade using automated trading" },
            ]
        }
    ]

    const initialValues = {};
    for (const q of questions) {
        initialValues[q.name] = data[q.name];
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues,
        validate: (values) => {
            const errors: any = {};
            for (const q of questions) {
                if (!values[q.name]) {
                    errors[q.name] = "Answer required!"
                }
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {};
            for (const q of questions) {
                data[q.name] = values[q.name];
            }
            onSelection(nextStep, data);
        }
    });

    return (
        <Row>
 
            <Col {...colWidths[0]}>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    {
                        questions.map(q =>
                            <Card key={q.name}>
                                <CardBody>
                                    <p className="fs-4">
                                        {q.label}
                                    </p>
                                    <Row key={q.name} className="p-5 pb-3 pt-3">
                                        <FormRadio name={q.name} options={q.options} handleChange={validation.handleChange} data={data}/>
                                    </Row>
                                </CardBody>
                            </Card>
                        )
                    }

                    <div className="nav-buttons">
                        <Button color="primary" outline onClick={() => onSelection(backStep)}>Back</Button>
                        <Button color="primary" className="btn btn-primary" type="submit">
                            Next
                        </Button>
                    </div>
                </Form>
            </Col>
       
            <Col {...colWidths[1]}>
                <SetUpHelp help={help}/>
            </Col>
        </Row>
    );
}

export default StepExperience;