import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { Link, useNavigate } from "react-router-dom";

// import images
import logoImg from "../../assets/images/logo-dark.png";

const Register = props => {

  const [passwordShow, setPasswordShow] = useState(false);

  //meta title
  document.title = "Register | Vertex Robot Trading";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      displayName: '',
      password: '',
    },
    validationSchema: Yup.object({
      displayName: Yup.string().required("Please Enter Your Full Name"),
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
    }
  });


  const selectAccountState = (state) => state.Account;
  const AccountProperties = createSelector(
    selectAccountState,
    (account) => ({
      user: account.user,
      registrationError: account.registrationError,
      success: account.success,
      loading: account.loading,
    })
  );

  const {
    user,
    registrationError, success, loading
  } = useSelector(AccountProperties);

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

useEffect(() => {
  success && setTimeout(() => navigate("/"), 2000)
}, [success])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="banner-top">
                  <Row>
                    <Col className="col-7">
                      <div className="p-4">
                        <h4>Vertex Robot Trading</h4>
                        <p>Try advanced robots for free.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logoImg} alt="" className="p-4 img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">Full Name</Label>
                        <Input
                          name="displayName"
                          type="text"
                          placeholder="Enter your full name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.displayName || ""}
                          invalid={
                            validation.touched.displayName && validation.errors.displayName ? true : false
                          }
                        />
                        {validation.touched.displayName && validation.errors.displayName ? (
                          <FormFeedback type="invalid">{validation.errors.displayName}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            type={passwordShow ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Vertex Robot Trading{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 text-center">
                Already have an account ?
                <Link to="/login" className="text-primary">
                    <h3>Login</h3>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="footer" style={{left: 0}}>
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Vertex Robot Trading</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">

              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Register;
