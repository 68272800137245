import React from "react";
import { Card } from "reactstrap";


const SetUpHelp = ({ help }) => {


    return (
        <React.Fragment>     
            { help.title &&
            <Card className="p-3">
                <p className="fs-6 mb-3">
                    {help.title}
                </p>
                {help.items.map((item, i) => (
    
                    <div key={i} className="faq-box d-flex mb-4">
                        <div className="flex-shrink-0 me-3 faq-icon">
                            <i className="bx bx-help-circle font-size-20 text-success" />
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="font-size-15">
                                {item.title}
                            </h5>
                            <p className="text-muted">
                                {item.text}
                            </p>
                        </div>
                    </div>
                ))}
    
    
            </Card>
            }
        </React.Fragment>
    );
}

export default SetUpHelp;