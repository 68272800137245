import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col, Container, Row,
  Spinner
} from "reactstrap";

import StepHaveBroker from "./StepHaveBroker";
import StepExistingBroker from "./StepExistingBroker";
import StepAreYouAnAffilate from "./StepAreYouAnAffilate";
import StepExistingBrokerAllSetup from "./StepExistingBrokerAllSetup";
import StepCountry from "./StepCountry";
import StepRecomendedBroker from "./StepRecomendedBroker";
import StepTasks from "./StepTasks";
import StepStart from "./StepStart";
import StepExperience from "./StepExperience";
import StepAccountSize from "./StepAccountSize";
import StepNotAnAffilate from "./StepNotAnAffilate";
import { isDownloadEnabled } from "helpers/config";
import { analytics } from "helpers/analytics_helper";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { updateProfile } from "store/actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export enum Step {
  TASKS = "TASKS",
  START = "START",
  HAVE_BROKER = "HAVE_BROKER",
  SELECT_EXISTING_BROKER = "SELECT_EXISTING_BROKER",
  COUNTRY = "COUNTRY",
  ARE_YOU_AN_AFFILATE = "ARE_YOU_AN_AFFILATE",
  RECOMENDED_BROKER = "RECOMENDED_BROKER",
  EXISTING_BROKER_ALL_SETUP = "EXISTING_BROKER_ALL_SETUP",
  EXPERIENCE = "EXPERIENCE",
  ACCOUNT_SIZE = "ACCOUNT_SIZE",
  NOT_AN_AFFILATE = "NOT_AN_AFFILATE"
}

const navigate = {
  START: { nextStep: Step.EXPERIENCE },
  EXPERIENCE: { nextStep: Step.ACCOUNT_SIZE, backStep: Step.START },
  ACCOUNT_SIZE: { nextStep: Step.COUNTRY, backStep: Step.EXPERIENCE },
  COUNTRY: { nextStep: Step.HAVE_BROKER, backStep: Step.ACCOUNT_SIZE },
  HAVE_BROKER: { nextStep: [Step.SELECT_EXISTING_BROKER, Step.RECOMENDED_BROKER], backStep: Step.COUNTRY },
  SELECT_EXISTING_BROKER: { nextStep: Step.ARE_YOU_AN_AFFILATE, backStep: Step.HAVE_BROKER },
  ARE_YOU_AN_AFFILATE: { nextStep: [Step.EXISTING_BROKER_ALL_SETUP, Step.NOT_AN_AFFILATE], backStep: Step.SELECT_EXISTING_BROKER },
  NOT_AN_AFFILATE: { nextStep: Step.RECOMENDED_BROKER, backStep: Step.ARE_YOU_AN_AFFILATE },
  EXISTING_BROKER_ALL_SETUP: {nextStep: Step.TASKS, backStep: Step.ARE_YOU_AN_AFFILATE},
  RECOMENDED_BROKER: { nextStep: Step.TASKS, backStep: Step.HAVE_BROKER },
}

const help: any = {
  TASKS: {},
  START: {},
  EXPERIENCE: {
    title: "Questions", 
    items: [
      {title: "Info", text: "Tell us a bit about yourself."},
      {title: "Why?", text: "Completing this survey will help us understand your goals, so you get the best experience."},
      {title: "Brokers", text: "Allows us to recommend which broker to use"},
    ]},
  ACCOUNT_SIZE: {},
  HAVE_BROKER: {
    title: "Brokers", 
    items: [
      {title: "Why do I need a broker?", text:  "A broker provides you with a trading account that you can fund and allows vertex automated trading robots to place trades using your account."},
      {title: "Our recommended brokers", text: "In order to run one of our trading robots you need a broker that allows for robot compatabillity, our selected list of brokers fully support all your needs for automated trading."},
    ]
  },
  SELECT_EXISTING_BROKER: {},
  ARE_YOU_AN_AFFILATE: {},
  NOT_AN_AFFILATE: {},
  EXISTING_BROKER_ALL_SETUP: {},
  COUNTRY: {
    title: "Countries", 
    items: [
      {title: "Your country", text:  "Not all brokers offer an acount for your country. Select the country here so that we can best recommend what broker to use."},
    ]
  },
  RECOMENDED_BROKER: {},
}

// const colWidths = [];


const SetUp = (props) => {

  const { stepId } = useParams();

  const dispatch = useDispatch();
  const [surveyData, setSurveyData] = useState({});
  const [tasks, setTasks] = useState([]);
  const [step, setStep] = useState(null);
  const [colWidths, setColWidths] = useState([]);

  const { profile, loadingProfile } = useSelector(createSelector((state: any) => state.profile, (profile: any) => ({profile: profile.profile, loadingProfile: profile.loading})));

  
  const onSelection = (nextStep: Step, data?: any) => {
    if (data) {
      let survey = { ...surveyData, ...data, step, nextStep };
      let tasksList = null;
      if (data.isSurveyComplete) {
        tasksList = createTasks(survey);
      }
      setSurveyData(survey);
      setTasks(tasksList);
      dispatch(updateProfile({survey, ...tasksList && {tasks: tasksList} }));
      analytics.identify(getFirebaseBackend().getCurrentUser().uid, survey);
    }
    selectStep(nextStep);
  };

  const selectStep = (step: Step) => {
    if (help[step].title) {
      setColWidths([{ md: "8" }, { md: "4" }]); 
    } else {
      setColWidths([{ md: "12" }, {}]);
    }
    setStep(step);
  }

  const createTasks = (data: any) => {
    let list = [];
    if (data.isNewSignUpBroker) {
      list.push({ type: data.brokerId, action: "broker-link", title: "Register with broker", description: `Complete sign up with <strong>${data.brokerName || "Broker"}</strong>`, isComplete: false });
    } else {
      list.push({ type: data.brokerId, action: "broker-link", title: "Register with broker", description: `Complete sign up with <strong>${data.brokerName || "Broker"}</strong>`, isComplete: true });
    }

    if (Number(data.investmentSize) <= 5000) {
        list.push({type: data.brokerId, title: "Create MT4 cent trading account", description: `Open a MT4 <strong>cent</strong> trading account with leverage 1:500`, isComplete: false });
        list.push({type: data.brokerId, title: "Deposit funds", description: `Fund your cent trading account with a minimum of $500`, isComplete: false });
    } else {
        list.push({type: data.brokerId, title: "Create MT4 standard trading account", description: `Open an MT4 standard trading account with leverage 1:500`, isComplete: false });
        list.push({type: data.brokerId, title: "Deposit funds", description: `Fund your standard trading account with a minimum of $5000`, isComplete: false });
    }

    list.push({type: "new-robot", action: "new-robot", title: "Create vertex robot", description: `With your trading Account ID and Password choose and create a robot`, isComplete: false });
    list.push({type: "run-robot", title: `Run${isDownloadEnabled ? " or Download" : ""} your robot`, description: `Run using our free 24/7 cloud service${isDownloadEnabled ? " or download and run in your own MetaTrader 4 software" : ""}`, isComplete: false });
    return list;
  }

  useEffect(() => {
      if (!profile) {
        return;
      }
      if (profile.tasks) {
        setTasks(profile.tasks);
      }
      let stepStart = Step.START;
      if (profile.survey) {
        setSurveyData(profile.survey);
        if(profile.survey.isSurveyComplete) {
          stepStart = Step.TASKS;
        } else if(profile.survey.nextStep) {
          stepStart = Step[profile.survey.nextStep];
        } 
      } else if (stepId == "go") {
        stepStart = navigate[Step.START].nextStep;
      }
      selectStep(stepStart);
  }, [profile]);

  return (

        <Container fluid>
          <Row className="justify-content-center">
            <Col xxl="11">
              { loadingProfile ? <Row><Card className="p-5"><CardBody className="m-auto p-5"><Spinner/></CardBody></Card></Row> :
                <Row>
                  {step == Step.TASKS && <StepTasks key={step} data={surveyData} tasks={tasks} colWidths={colWidths} help={help[step]}/>}
                  {step == Step.START && <StepStart onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} help={help[step]}/>}
                  {step == Step.EXPERIENCE && <StepExperience onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.ACCOUNT_SIZE && <StepAccountSize onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.HAVE_BROKER && <StepHaveBroker onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.SELECT_EXISTING_BROKER && <StepExistingBroker onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.COUNTRY && <StepCountry onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.ARE_YOU_AN_AFFILATE && <StepAreYouAnAffilate onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.NOT_AN_AFFILATE && <StepNotAnAffilate onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.RECOMENDED_BROKER && <StepRecomendedBroker onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                  {step == Step.EXISTING_BROKER_ALL_SETUP && <StepExistingBrokerAllSetup onSelection={onSelection} key={step} data={surveyData} colWidths={colWidths} nextStep={navigate[step].nextStep} backStep={navigate[step].backStep} help={help[step]}/>}
                </Row>
              }
              {/* <Row>
              {step}
              </Row>
              <Row>
                {JSON.stringify(surveyData, null, 4)}
              </Row>
              <Row>
                {JSON.stringify(tasks, null, 4)}
              </Row> */}
            </Col>
          </Row>
        </Container>
  );
};

SetUp.propTypes = {
  navigate: PropTypes.object,
};

export default SetUp;