import { profile } from "console"
import { 
  UPDATE_PROFILE_ERROR, 
  UPDATE_PROFILE_SUCCESS, 
  UPDATE_PROFILE, 
  RESET_PROFILE_FLAG, 
  GET_PROFILE,
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  GET_SUB_ERROR,
  GET_SUB,
  GET_SUB_SUCCESS,
  UPDATE_SUB_ERROR,
  UPDATE_SUB,
  UPDATE_SUB_SUCCESS,
  GET_PROFILE_ADMIN,
  GET_PROFILE_ADMIN_SUCCESS,
  GET_PROFILE_ADMIN_ERROR} from "./actionTypes"

export const updateProfile = profile => {
  return {
    type: UPDATE_PROFILE,
    payload: { profile },
  }
}

export const updateProfileSuccess = msg => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: msg,
  }
}

export const updateProfileError = error => {
  return {
    type: UPDATE_PROFILE_ERROR,
    payload: error,
  }
}

export const getProfile = () => {
  return {
    type: GET_PROFILE
  }
}

export const getProfileSuccess = (profile) => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: profile,
  }
}

export const getProfileError = error => {
  return {
    type: GET_PROFILE_ERROR,
    payload: error,
  }
}

export const getProfileAdmin = (userId) => {
  return {
    type: GET_PROFILE_ADMIN,
    payload: {userId},
  }
}

export const getProfileAdminSuccess = (profile) => {
  return {
    type: GET_PROFILE_ADMIN_SUCCESS,
    payload: profile,
  }
}

export const getProfileAdminError = error => {
  return {
    type: GET_PROFILE_ADMIN_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const getSub = (userId) => {
  return {
    type: GET_SUB,
    payload: {userId},
  }
}

export const getSubSuccess = (data) => {
  return {
    type: GET_SUB_SUCCESS,
    payload: data,
  }
}

export const getSubError = error => {
  return {
    type: GET_SUB_ERROR,
    payload: error,
  }
}

export const updateSub = (userId, unsubs) => {
  return {
    type: UPDATE_SUB,
    payload: {userId, unsubs},
  }
}

export const updateSubSuccess = msg => {
  return {
    type: UPDATE_SUB_SUCCESS,
    payload: msg,
  }
}

export const updateSubError = error => {
  return {
    type: UPDATE_SUB_ERROR,
    payload: error,
  }
}