
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "reactstrap";
import { updateBot } from "store/actions";

const LoginModal = ({open, setOpen, data}) => {

    const dispatch = useDispatch();

    const [passwordShow, setPasswordShow] = useState(false);
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const toggleOpen = () => {
        setOpen(!open);
    }

    const updateLogin = () => {
        dispatch(updateBot("UPDATE_LOGIN", data, {login, password}));
        setOpen(false);
    }

    return (
        <Modal
            isOpen={open}
            toggle={() => {
                toggleOpen()
            }}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Update Broker Login Details</h5>
                <button type="button"
                    onClick={() => {
                        setOpen(false);
                    }} className="btn-close"></button>
            </div>
            <div className="modal-body">
                <form>
                    <div className="mb-3">
                        <label htmlFor="form-login" className="col-form-label">Account ID</label>
                        <input type="text" className="form-control" name="login" id="form-login" onChange={e => setLogin(e.target.value)} value={login} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="form-password" className="col-form-label">Password</label>
                        <div className="input-group auth-pass-inputgroup">
                            <input type={passwordShow ? "text" : "password"} className="form-control" name="password" id="form-password" onChange={e => setPassword(e.target.value)} value={password} />
                            <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button onClick={() => setOpen(false)} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button onClick={() => updateLogin()} type="button" className="btn btn-primary">Update</button>
            </div>
        </Modal>
    )
}

export default LoginModal;