/* BOTS */
export const LIST_BOTS = "LIST_BOTS"
export const LIST_BOTS_SUCCESS = "LIST_BOTS_SUCCESS"
export const LIST_BOTS_FAIL = "LIST_BOTS_FAIL"

/**
 * get BOT details
 */
export const GET_BOT = "GET_BOT"
export const GET_BOT_SUCCESS = "GET_BOT_SUCCESS"
export const GET_BOT_FAIL = "GET_BOT_FAIL"

/**
 * new BOT builder pages
 */
export const NEW_BOT = "NEW_BOT";
export const NEW_BOT_SUCCESS = "NEW_BOT_SUCCESS";

/**
 * add BOT
 */
export const ADD_BOT = "ADD_BOT"
export const ADD_BOT_SUCCESS = "ADD_BOT_SUCCESS"
export const ADD_BOT_FAIL = "ADD_BOT_FAIL"

/**
 * Edit BOT
 */
export const UPDATE_BOT = "UPDATE_BOT"
export const UPDATE_BOT_SUCCESS = "UPDATE_BOT_SUCCESS"
export const UPDATE_BOT_FAIL = "UPDATE_BOT_FAIL"

/**
 * Delete BOT
 */
export const DELETE_BOT = "DELETE_BOT"
export const DELETE_BOT_SUCCESS = "DELETE_BOT_SUCCESS"
export const DELETE_BOT_FAIL = "DELETE_BOT_FAIL"

export const LIST_BROKER_SERVERS = "LIST_BROKER_SERVERS"
export const LIST_BROKER_SERVERS_SUCCESS = "LIST_BROKER_SERVERS_SUCCESS"
export const LIST_BROKER_SERVERS_FAIL = "LIST_BROKER_SERVERS_FAIL"


export const LIST_BROKERS = "LIST_BROKERS"
export const LIST_BROKERS_SUCCESS = "LIST_BROKERS_SUCCESS"
export const LIST_BROKERS_FAIL = "LIST_BROKERS_FAIL"

export const UPDATE_BOT_DATA = "UPDATE_BOT_DATA";