import React, { useEffect, useState } from "react";
import { Alert, Button, Card, CardBody, Col, Input, Label, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { BROKER_IMAGES } from "./BrokerImages";
import { updateProfile } from "helpers/user_profile_api";
import GetStartedCard from "./GetStartedCard";
import { withTranslation } from "react-i18next";
import { isDownloadEnabled } from "helpers/config";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { getBroker } from "helpers/bot_trader_api";

const StepStart = ({ t, data, onSelection, colWidths, nextStep, help }) => {

    const [taskData, setTaskData] = useState([]);
    const [selectedBroker, setSelectedBroker] = useState({link: "#", name: ""});

    return (

        <React.Fragment>
            <GetStartedCard data={data} onSelection={() => onSelection(nextStep)} />
        </React.Fragment>
    );
};

export default withTranslation()(StepStart);