import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import ChatNotifications from "pages/Chat/ChatNotification";

const NotificationDropdown = props => {

  const { currentUser } = useSelector(createSelector((state: any) => state.login, (login: any) => ({currentUser: login.user})));

  const ChatProperties = createSelector(
    (state: any) => state.chats,
    (chats: any) => ({
      chats: chats.chats,
      lastAction: chats.lastAction,
      lastActionIsCurrentOpenChannel: chats.lastActionIsCurrentOpenChannel,
      totalNewCount: chats.totalNewCount,
      loading: chats.loadingContacts || chats.loadingChannels,
    })
  );
  const { chats, lastAction, lastActionIsCurrentOpenChannel, totalNewCount, loading } = useSelector(ChatProperties);
  
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (lastAction.indexOf("newMessage") >= 0 && !lastActionIsCurrentOpenChannel) {
      setMenu(true);
    }
  }, [lastAction, lastActionIsCurrentOpenChannel]);

  return (
    <React.Fragment>
      {totalNewCount > 0 && <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{totalNewCount}</span>
        </DropdownToggle>

        <DropdownMenu className="notifications-dropdown dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"><span className="text-muted">{props.t("Notifications")}</span> {totalNewCount > 0 && `(${totalNewCount})`}</h6>
              </Col>
              <div className="col-auto">
                {/* <a href="/chats" className="small">
                  {" "}
                  View All
                </a> */}
              </div>
            </Row>
          </div>

          <SimpleBar style={{overflowX: "hidden", maxHeight: "230px" }} autoHide={true}>
            <ChatNotifications channels={chats} currentUser={currentUser} onClick={() => setMenu(false)}/>
          </SimpleBar>
          <div className="p-2 mt-2 border-top d-flex h-100">
            <Link to="/chat" onClick={() => setMenu(false)} className="flex-fill btn btn-sm btn-link font-size-14">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
            <Link to="#" onClick={() => setMenu(false)} className="m-auto font-size-16">
              <i className="mdi mdi-close" />
            </Link>


          </div>
        </DropdownMenu>
      </Dropdown>
      }
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};