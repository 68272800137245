import { listBrokers } from "helpers/bot_trader_api";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardImg, CardTitle, Col, Row, Spinner } from "reactstrap";
import { BROKER_IMAGES } from "./BrokerImages";

const RecommendedBrokers = ({onClick, useLink, label, profile, viewAll}: {onClick?: Function, useLink: boolean, label: string, profile: any, viewAll?: boolean}) => {

    const [recomended, setRecomended] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (viewAll) {
            listBrokers().then(list => {
                setRecomended(list);
                setLoading(false);
            });
            return;
        }
        if (!profile.survey?.country) {
            return;
        }
        listBrokers([profile.survey.country, profile.survey.continent]).then(list => {
            setRecomended(list);
            setLoading(false);
        });
    }, [profile, viewAll]);

    return (
        <>
            {!profile.survey?.country && !viewAll ? <div>
                <Card outline color="primary" className="m-auto">
                    <CardBody>Please complete <Link to="/setup/go">Setup Survey</Link> for a list of recommended brokers</CardBody>
                </Card>
            </div> :
                loading 
                ? <Card className="p-5"><CardBody className="m-auto p-5"><Spinner/></CardBody></Card>
                : recomended.map(b => (
                    <Card outline color="light" className="border" key={b.id}>
                        <Row className="no-gutters align-items-center">
                            <Col md={8}>
                                <CardBody>
                                    <CardTitle className="fs-3">{b.name}</CardTitle>
                                    <div className="vstack gap-1 m-2 pb-2">
                                        {b.features.map((item, i) => (
                                            <div key={b.id + i}>
                                                <i className={"mdi me-1 " + (item.isPositive ? "mdi-check-bold" : "mdi-block-helper")} /> {item.label}
                                            </div>
                                        ))}
                                    </div>
                                    <Link onClick={() => onClick && onClick(b)} to={useLink ? b.link : "#"}
                                        target={useLink ? "_blank" : ""}
                                        className="btn btn-primary btn-block">
                                        {label} {b.name}
                                    </Link>
                                </CardBody>
                            </Col>
                            <Col md={4}>
                                <CardImg style={{width: "200px"}} className="img-fluid pe-4 border" src={BROKER_IMAGES[b.id]} alt={b.name} />
                            </Col>
                        </Row>
                    </Card>
                ))
            }
        </>
    );
}

export default RecommendedBrokers;