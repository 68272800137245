

const ChatSenderName = ({channel, message, currentUser}) => {


    const getSenderName = (message) => {
        if (!message) {
            return "";
        }
        if (message.senderId == currentUser.uid) {
            return "You"
        }
        return channel.contacts?.find(c => c.uid == message.senderId)?.name || "???";
    }

    return <>
        {getSenderName(message)}
    </>

}

export default ChatSenderName;