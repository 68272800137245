import { Analytics as FbAnalytics, getAnalytics, logEvent } from "firebase/analytics";

// analytics.js
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'vertex-robots-app',
  debug: false,
  plugins: [
    // googleAnalytics({
    //   trackingId: process.env.REACT_APP_MEASUREMENTID,
    // })
    googleAnalytics({
      measurementIds: [process.env.REACT_APP_MEASUREMENTID],
      debug: false,
    })
  ]
})


let fbAnalytics : FbAnalytics = null;

const _getFbAnalytics = () => {
    if (!analytics) {
        // fbAnalytics = getAnalytics();
    }
    return fbAnalytics;
}

const logSelectContent = (content_type, content_id) => {
    logEvent(_getFbAnalytics(), 'select_content', {
      content_type,
      content_id
    });
  }
  
const logScreenView = (firebase_screen, firebase_screen_class) => {
    logEvent(_getFbAnalytics(), 'screen_view', {
        firebase_screen,
        firebase_screen_class
    });
}
  
  /* Listener for pushstate route changes */
const onRouteChange = (fn) => {
    if (typeof window === 'undefined') return
    const { addEventListener, history, location } = window
    // Set initial pathname
    let previous = location.pathname
    // Observe native navigation
    addEventListener('popstate', () => fn(location.pathname))
    // Observe manual navigation
    ;['push', 'replace'].map((type) => {
      const state = `${type}State`
      const historyFn = history[state]
      history[state] = function() {
        const updated = trimPath(arguments[2])
        if (previous !== updated) {
          previous = updated // Set updated pathname
          setTimeout(() => fn(arguments[2]), 0) // Debounce delay for SPA
        }
        return historyFn.apply(history, arguments)
      }
    })
}
  
const remove = (str, char) => {
    const has = str.indexOf(char)
    return (has > -1) ? str.slice(0, has) : str
}
  
  // Trim hash and params off parcial url path
const trimPath = (urlPath) => {
    if (!urlPath) {
        return "/";
    }
    ['#', '?'].forEach((x) => urlPath = remove(urlPath, x))
    return urlPath
}
  
export { logScreenView, logSelectContent, analytics, onRouteChange };