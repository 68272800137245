import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
    Col, Container, Row, Label, Button, Form, Input, FormFeedback, Card, CardBody,
    CardText,
    CardHeader,
    Alert,
    Spinner
} from "reactstrap";
import Breadcrumbs, { DASHBOARD_ITEM } from "components/Common/Breadcrumb";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { downLoadBot } from "helpers/bot_trader_api";

import { getBot } from "store/bot-trader/actions";
import { withTranslation } from "react-i18next";

const FinishBotPage = (props) => {

    document.title = "New Robot | Vertex Robot Trading";

    const dispatch = useDispatch();

    const { botId } = useParams();

    const selectBotState = (state) => (state.bots);
    const BotProperties = createSelector(
      selectBotState,
      (bots) => ({
        bot: bots.bot,
        newBotResponse: bots.newBotResponse,
        loading: bots.loading,
      })
    );
    const {
        bot, newBotResponse, loading
    } = useSelector(BotProperties);

    const onDownload = () => {
        downLoadBot(bot.eaId);
    }

    useEffect(() => {
        dispatch(getBot(botId));
    }, [dispatch]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="New Robot" items={[DASHBOARD_ITEM, {title: "My Robots", url: "/bots"}]} />
                    { loading && <Row><Card className="p-5"><CardBody className="m-auto p-5"><Spinner/></CardBody></Card></Row>}
                    { !loading && <Row className="justify-content-center">
                        <Col md="11" xl="8">
                            <div className="step-bar">
                                <div className="step complete">
                                    <button className="number">1</button>
                                    <p>Choose a robot</p>
                                </div>
                                <div className="bar-complete"></div>
                                <div className="step complete">
                                    <button className="number">2</button>
                                    <p>Select broker</p>
                                </div>
                                <div className="bar-complete"></div>
                                <div className="step active">
                                    <button className="number">3</button>
                                    <p className="px-3">Finish</p>
                                </div>
                            </div>

                            <Card outline color="success" className="border">
                                <CardHeader className="bg-transparent">
                                <h3 className="my-0 text-success">
                                    <i className="mdi mdi-check-all me-3" />All done!
                                </h3>
                                </CardHeader>
                                <CardBody>
                                {/* <CardTitle className="mt-0">card title</CardTitle> */}
                                { newBotResponse.message.split("|").map((m, index) => (
                                    <p key={"m-"+index} className="fs-5" dangerouslySetInnerHTML={{ __html: m }}/> 
                                ))}
                                </CardBody>
                            </Card>

                            {bot.isDownload && <Card outline color="info" className="border">
                                <CardHeader className="bg-transparent pb-0">
                                <h3 className="my-0 text-info">
                                    <i className="mdi mdi-download me-3" />Download
                                </h3>
                                </CardHeader>
                                <CardBody>
                                {/* <CardTitle className="mt-0">card title</CardTitle> */}
                                    <div className="fs-5">
                                        <ul>
                                            <li className="py-1">
                                                If you haven't already download the Expert Advisor here!
                                            </li>
                                            <li className="py-1">
                                                Add the Expert Advisor to <strong>{bot.symbol}</strong> chart and timeframe <strong>{bot.period}</strong>
                                            </li>
                                            <li className="py-1">
                                                For more information on how to add the Expert Advisor to MetaTrader please see <Link to={`/bots/download/${bot.id}`}>set up instructions.</Link>
                                            </li>
                                            <Alert color="success" className="mt-1 mb-4" role="alert">
                                                {props.t("Download Alert")}
                                            </Alert>
                                        </ul>
                                    </div>
                                   
                                </CardBody>
                                <Button onClick={() => onDownload()} color="primary"
                                    className="mt-0 m-2">
                                    <i className="mdi mdi-download me-3" />Download
                                </Button>
                            </Card>}
                            <div className="nav-buttons">
                                <Link to="/bots" className="btn btn-outline-primary">
                                    Finish
                                </Link>
                            </div>
                        </Col>
                    </Row> }
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(FinishBotPage));