import { Card, CardBody, Row, Col, Button } from "reactstrap";
import SetUpHelp from "./SetUpHelp";


const StepHaveBroker = ({ data, onSelection, colWidths, nextStep, backStep, help }) => {
    return (
        <Row>
            <Col {...colWidths[0]}>

                <Card>
                    <CardBody>
                        <p className="fs-4">
                            Please select your broker options?
                        </p>
                        <Card className="hover-select" onClick={() => onSelection(nextStep[1], {hasBrokerAllReady: false})}>
                            <CardBody>
                                <p className="fs-3 p-3">
                                    I don't currently have a broker
                                </p>
                            </CardBody>
                        </Card>
                        <Card className="hover-select" onClick={() => onSelection(nextStep[0], {hasBrokerAllReady: true})}>
                            <CardBody>
                                <p className="fs-3 p-3">
                                    I have a broker already
                                </p>
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>
                <div className="nav-buttons">
                    <Button color="primary" outline onClick={() => onSelection(backStep)}>Back</Button>
                </div>
            </Col>
            <Col {...colWidths[1]}>
                <SetUpHelp help={help} />
            </Col>
        </Row>

    );
}

export default StepHaveBroker;