import { getLocale } from "helpers/util";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Collapse, Offcanvas, OffcanvasBody, OffcanvasHeader, Table } from "reactstrap";
import { createSelector } from "reselect";
import { getProfileAdmin } from "store/actions";


const AdminUserProfileView = ({currentUser, user, show, toggleOpen}) => {

    const dispatch = useDispatch();

    const { profiles } = useSelector(createSelector((state: any) => state.profile, (profile: any) => ({profiles: profile.profiles})));

    const [profile, setProfile] = useState(null);

    const EXPERIENCE = {
        0: "New",
        1: "Some",
        2: "Regular"
    }

    useEffect(() => {
        console.log(user);
        if (user?.uid && !profiles[user.uid]) {
            console.log("load profile", user.uid);
            dispatch(getProfileAdmin(user.uid));
        }
        if (user?.uid && profiles[user.uid]) {
            console.log("set prof", profiles[user.uid]);
            setProfile(profiles[user.uid]);
        }
    }, [user, profiles]);

    const formatTrail = (profile) => {
        if (!profile?.startTrial) {
            return "Not started";
        }
        const endDate = moment(new Date(profile.endTrial)).locale(getLocale());
        const now = moment().locale(getLocale());

        if (now.isBefore(endDate)) {
            return endDate.diff(now, "day") + " day(s) left";
        }
        return endDate.format("MMM DD, LT") + " (Ended)";
    }

    const formatBoolean = (bool: boolean) => {
        return bool ? "Yes" : "No";
    }

    return <>
        <Offcanvas
            isOpen={show}
            direction="end"
            scrollable
            backdrop={false}
            toggle={toggleOpen}>
            <OffcanvasHeader toggle={toggleOpen}>
                User Profile
            </OffcanvasHeader>
            <OffcanvasBody>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Personal Information</CardTitle>
                  <p className="text-muted mb-4"></p>
                  {profile &&
                    <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                        <tbody>
                            <tr>
                            <th scope="row">User ID</th>
                            <td>{profile.id}</td>
                            </tr>
                            <tr>
                            <th scope="row">Short ID</th>
                            <td>{profile.shortId}</td>
                            </tr>
                            <tr>
                            <th scope="row">Name</th>
                            <td>{user.name}</td>
                            </tr>

                            <tr>
                            <th scope="row">E-mail</th>
                            <td>{user.email}</td>
                            </tr>
                            <tr>
                            <th scope="row">Trial</th>
                            <td>{formatTrail(profile)}</td>
                            </tr>
                        </tbody>
                        </Table>
                    </div>
                  }
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Survey</CardTitle>
                  <p className="text-muted mb-4"></p>
                  {profile?.survey &&
                    <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                        <tbody>
                            <tr>
                            <th scope="row">Country</th>
                            <td>{profile.survey.country}</td>
                            </tr>
                            <tr>
                            <th scope="row">Trading</th>
                            <td>{EXPERIENCE[profile.survey.experienceTrading]}</td>
                            </tr>
                            <tr>
                            <th scope="row">Automated</th>
                            <td>{EXPERIENCE[profile.survey.experienceAutomated]}</td>
                            </tr>
                            <tr>
                            <th scope="row">Account Size</th>
                            <td>{profile.survey.investmentSize}</td>
                            </tr>
                            <tr>
                            <th scope="row">Profit Goal</th>
                            <td>{profile.survey.profitSize}%</td>
                            </tr>
                            <tr>
                            <th scope="row">Has Broker</th>
                            <td>{formatBoolean(profile.survey.hasBrokerAllReady)}</td>
                            </tr>
                            <tr>
                            <th scope="row">New Broker Sign-up</th>
                            <td>{formatBoolean(profile.survey.isNewSignUpBroker)}</td>
                            </tr>
                            <tr>
                            <th scope="row">Broker</th>
                            <td>{profile.survey.brokerName}</td>
                            </tr>
                            <tr>
                            <th scope="row">Is Survey Complete</th>
                            <td>{formatBoolean(profile.survey.isSurveyComplete)}</td>
                            </tr>
                        </tbody>
                        </Table>
                    </div>
                  }
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Tasks</CardTitle>
                  <p className="text-muted mb-4"></p>
                  <ol>
                    {profile?.tasks && profile.tasks.map((task, index) => 
                        <li key={`tsk-${index}`}>
                            {task.title}
                            <span className="float-end">{formatBoolean(task.isComplete)}</span>
                        </li>
                    )}
                  </ol>
                </CardBody>
              </Card>

            </OffcanvasBody>
        </Offcanvas>
    </>

}

export default AdminUserProfileView;