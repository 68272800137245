import React from "react"
import { Container, Row, Col, CardBody } from "reactstrap";
import Breadcrumbs, { DASHBOARD_ITEM } from "../../components/Common/Breadcrumb"
import PaymentForm from "../../components/Common/PaymentForm"
import { useParams } from 'react-router-dom';

const PagesPayment = () => {

  const { productId, priceId, priceType } = useParams();
  document.title = "Payment | Vertex Robot Trading";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <Breadcrumbs title="Payment" items={[DASHBOARD_ITEM, {title: "Pricing", url: "/pricing"}]} />
          <Row className="justify-content-center">
            <Col sm={6}>
              <div className="text-center mb-4">
                <h4>Checkout Details</h4>
                <p className="text-muted">
                  
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8} xl={6}>
              <PaymentForm productId={productId} priceId={priceId} priceType={priceType}/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default PagesPayment
