import { Card, CardBody, Row, Col, Button, CardTitle, CardImg, Spinner } from "reactstrap";

import SetUpHelp from "./SetUpHelp";
import RecommendedBrokers from "./RecommendedBrokers";


const StepRecomendedBroker = ({ data, onSelection, colWidths, nextStep, backStep, help }) => {


    const onOpenAccountWith = (broker: any) => {
        onSelection(nextStep, {isNewSignUpBroker: true, brokerId: broker.id, brokerName: broker.name, isSurveyComplete: true});
    }

    return (
        <Row>
            <Col {...colWidths[0]}>

                <Card>
                    <CardBody>
                        <p className="fs-4">
                            We recomend!
                        </p>
                        <RecommendedBrokers onClick={onOpenAccountWith} useLink={false} label={"Select"} profile={{survey: data}}/>
                    </CardBody>
                </Card>


                <div className="nav-buttons">
                    <Button color="primary" outline onClick={() => onSelection(backStep)}>Back</Button>
                </div>


            </Col>
            <Col {...colWidths[1]}>
                <SetUpHelp help={help}/>

            </Col>
        </Row>

    );
}

export default StepRecomendedBroker;