import React, { useEffect, useState } from "react"
import { Container, Row, Col, CardBody, Card, CardTitle, CardText, CardHeader } from "reactstrap";
import Breadcrumbs, { DASHBOARD_ITEM } from "../../components/Common/Breadcrumb"
import { onCurrentUserSubscriptionUpdate, onCurrentUserPaymentUpdate, PaymentSnapshot, SubscriptionSnapshot, getProduct, getPrice } from "ext-firestore-stripe-payments";
import { getPayments } from "helpers/firebase_helper";
import { useParams } from "react-router-dom";
import { analytics } from "helpers/analytics_helper";

const PagesPaymentSuccess = () => {

  const [ paymentId, setPaymentId ] = useState(" - - - - - - - - ");
  const [ loading, setLoading ] = useState(true);
  const { productId, priceId } = useParams();

  useEffect(() => {

    const payments = getPayments();

    // onCurrentUserSubscriptionUpdate(
    //   payments,
    //   (snapshot: SubscriptionSnapshot) => {
    //     console.log("onCurrentUserSubscriptionUpdate", snapshot);
    //     for (const change of snapshot.changes) {
    //       if (change.type === "added") {
    //         console.log(`New subscription change:`, change);
    //       }
    //     }
    //   }
    // );

    onCurrentUserPaymentUpdate(
      payments,
      async (snapshot: PaymentSnapshot) => {
        for (const change of snapshot.changes) {
          if (change.type === "added" && change.payment.prices) {
            for (const price of change.payment?.prices) {

              if (price.product == productId && price.price == priceId) {
                
                setPaymentId(change.payment.id);

                const product = await getProduct(payments, productId);
                const price = await getPrice(payments, productId, priceId);

                const aData = {
                  transaction_id: change.payment.id,
                  currency: change.payment.currency,
                  value: change.payment.amount / 100,
                  items: [{
                    item_id: price.description,
                    price: price.unit_amount / 100,
                    item_name: product.name
                  }],
                  coupon: change.payment.metadata?.coupon,
                  priceId: price.price,
                  productId: price.product
                };
                analytics.track('purchase', aData);
                
              }
            }
            console.log(`New payment change:`, change);
          }
        }
        setLoading(false);
      }
    );
  }, []);

  document.title = "Payment Successfull | Vertex Robot Trading";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Payment Success" items={[DASHBOARD_ITEM]} />
          <Row className="justify-content-center">
            <Col sm={6}>
              <div className="text-center mb-4">
                <h4>Checkout Successful</h4>
                <p className="text-muted">
                  
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8} xl={6}>

              <Card outline color="success" className="border">
                <CardHeader className="bg-transparent">
                  <h5 className="my-0 text-success">
                    <i className="mdi mdi-check-all me-2" />Payment Successfull
                  </h5>
                </CardHeader>
                <CardBody>
                  <CardTitle className="mt-0">
                    <span className="me-3">Payment reciept: </span>
                    <span className={loading ? "placeholder" : ""}>{paymentId}</span>
                  </CardTitle>
                  <CardText>
                    Thank you, for your purchase!
                  </CardText>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default PagesPaymentSuccess
