import { Card, CardBody, Row, Col, Button } from "reactstrap";
import SetUpHelp from "./SetUpHelp";


const StepAreYouAnAffilate = ({ data, onSelection, colWidths, nextStep, backStep, help }) => {

    return (
        <Row>
            <Col {...colWidths[0]}>

                <Card>
                    <CardBody>
                        <p className="fs-4">
                            Is your account affilated?
                        </p>
                        <Card className="hover-select" onClick={() => onSelection(nextStep[0], {isAlreadyAffilate: true})}>
                            <CardBody>
                                <p className="fs-3 p-3">
                                    Yes my broker account is already affilated with <strong>Vertex Forex Trading</strong>
                                </p>
                            </CardBody>
                        </Card>
                        <Card className="hover-select" onClick={() => onSelection(nextStep[1], {isAlreadyAffilate: false})}>
                            <CardBody>
                                <p className="fs-3 p-3">
                                    No my broker account is not affilated, please select for more options
                                </p>
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>

                <div className="nav-buttons">
                    <Button color="primary" outline onClick={()=>onSelection(backStep)}>Back</Button>
                </div>


            </Col>
            <Col {...colWidths[1]}>
                <SetUpHelp help={help}/>
            </Col>
        </Row>

    );
}

export default StepAreYouAnAffilate;