import React from "react";
import { FormattedNumber } from 'react-intl'

const CurrencyFormat = ({value, currency = undefined, isNull = undefined, noTrailingZeros = false}) => {

  return (
    <React.Fragment>
      {
        isNull != undefined && isNull() 
        ? <div>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</div>
        : <FormattedNumber value={value || 0} style="currency" trailingZeroDisplay={noTrailingZeros ? "stripIfInteger" : "auto"} 
            currency={currency === undefined ? "USD" : currency} />
      }
    </React.Fragment>
  );
}

export default CurrencyFormat;