import ChatSenderName from "./ChatSenderName";


const ChatLastMessage = ({channel, message, currentUser}) => {

    return <>
        <i><ChatSenderName channel={channel} message={channel.lastMessage} currentUser={currentUser} />
                    {channel.lastMessage.text && ":"}</i>
                    {channel.lastMessage.text ? <span className="ms-1">{channel.lastMessage.text}</span> : " sent an image"}
    </>

}

export default ChatLastMessage;